import { MouseEventHandler } from "react";
import { useTranslation } from "i18n";
import { MdWhatsapp, MdPhoneIphone, MdEmail } from "react-icons/md";

import Reveal from "components/general/Reveal";

export default function Footer() {
  const onClickWAIcon: MouseEventHandler<HTMLAnchorElement> = e => {
    e.currentTarget.href =
      "https://wa.me/85298399614/?text=" +
      encodeURIComponent(document.location.href);
  };

  const { t } = useTranslation();

  return (
    <footer className="pb-2 mt-4 text-xs bg-gradient-to-b from-stone-100">
      <Reveal
        className="flex flex-col w-full max-w-screen-xl px-4 py-8 mx-auto mb-10 justify-evenly gap-x-2 gap-y-8 sm:flex-row sm:gap-8 slide-t"
        itemScope
        itemType="https://schema.org/Organization"
      >
        <div className="space-y-1" itemProp="name">
          <div>
            賀臨貿易有限公司
            <br />
            CONAN TRADING COMPANY LIMITED
          </div>
          <div>
            賀臨建材公司
            <br />
            CONAN BUILDING MATERIAL COMPANY
          </div>
        </div>
        <div className="space-y-2">
          <div className="flex gap-x-3">
            <a
              href="tel:+85298399614"
              className="flex items-center gap-2 hover:underline focus:underline"
              itemProp="telephone"
            >
              <MdPhoneIphone className="inline-block w-4 h-4" role="none" />{" "}
              +852-9839-9614
            </a>
            <a
              href="https://wa.me/85298399614"
              onClick={onClickWAIcon}
              className="p-0.5 text-green-500 transition-colors bg-green-200 rounded hover:bg-green-400 hover:text-white hover:shadow"
            >
              <MdWhatsapp className="w-4 h-4" role="img" />
            </a>
          </div>
          <a
            href="mailto:kasper@conanhk.com?subject=Conan+website+inquiry"
            className="flex items-center gap-2 hover:underline focus:underline"
            itemProp="email"
          >
            <MdEmail className="w-4 h-4" role="none" />
            kasper&#x40;conanhk.com
          </a>
        </div>
        <div
          className="space-y-2"
          itemProp="address"
          itemScope
          itemType="https://schema.org/PostalAddress"
        >
          <div lang="zh">九龍荔枝角青山道489至491號香港工業中心c座11樓5室</div>
          <div lang="en">
            Flat 5, 11/F, Block C, Hong Kong Industrial Centre,
            <br />
            489-491 Castle Peak Road, Lai Chi Kok, Kowloon, Hong Kong
          </div>
        </div>
      </Reveal>
      <div className="mt-4 text-xs text-center text-stone-300">
        {t("copy")} &copy; {Math.max(2023, new Date().getFullYear())}
      </div>
      <a
        href="https://wa.me/85298399614"
        onClick={onClickWAIcon}
        className="fixed z-10 flex items-center justify-center transition-transform rounded-full hover:scale-110 focus:scale-110 md:shadow-lg bg-gradient-to-br from-cream to-stone-400 w-9 h-9 md:w-12 md:h-12 bottom-4 md:bottom-6 md:right-4 right-2 base-btn"
      >
        <MdWhatsapp className="w-full h-full m-1 text-white" role="img" />
      </a>
    </footer>
  );
}
